<template>
    <div id="recoveryPassword">

        <div class="card-body p-4">
            <h5 class="mb-1 text-center">{{$t('recoveryPassword.inserisciNuovaPassword')}}</h5>
            <p>
                {{$t('recoveryPassword.descrizione')}}<br>
                {{$t('recoveryPassword.passwordPatternDescrizione')}}
            </p>

            <form class="form-horizontal" @submit.prevent="updatePassword()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-custom my-3">
                            <input type="password" class="form-control" v-model.trim="$v.password.$model" id="userpassword" required>
                            <label for="userpassword">{{$t('recoveryPassword.password.label')}}</label>
                            <div class="error pl-vertical-align-input custom-font" v-if="!$v.password.validatePassword">{{$t('recoveryPassword.password.erroreFormato')}}</div>
                        </div>

                        <div class="form-group form-group-custom mb-4">
                            <input type="password" class="form-control" v-model.trim="$v.passwordConfirm.$model" id="userpasswordconfirm" required>
                            <label for="userpasswordconfirm" class="custom-animation-transform">{{$t('recoveryPassword.confermaPassword.label')}}</label>
                            <div class="error pl-vertical-align-input custom-font" v-if="!$v.passwordConfirm.sameAsPassword">{{$t('recoveryPassword.confermaPassword.erroreCorrispondenza')}}</div>
                        </div>


                        <div class="mt-4">
                            <button class="btn btn-success btn-block waves-effect waves-light" type="submit">{{$t('recoveryPassword.aggiornaPassword')}}</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>

        <!-- Modal error -->
        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('recoveryPassword.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-error-body" class="modal-body">
                        <p id="message-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="goToLogin()" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->

        <!-- Modal error -->
        <div id="modalRestorePasswordSuccess" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalRestorePasswordSuccessLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalRestorePasswordSuccessLabel">{{$t('recoveryPassword.modale.resetPassword.titolo')}}</h5>
                    </div>
                    <div class="modal-body">
                        <p id="message-rps-body">{{$t('recoveryPassword.modale.resetPassword.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="goToLogin()" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->

        <!-- Modal overlay login -->
        <div id="modalOverlayLogin" class="modal" tabindex="-1" role="dialog" aria-labelledby="modalOverlayLoginLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent border-0">
                    <div id="modal-body-overlay-pause" class="modal-body">
                        <div class="w-100 text-center pb-4">
                            <img src="../assets/images/logo-concorsando-white.png">
                        </div>
                        <div class="w-100 text-center">
                            <div class="loader mx-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /Modal overlay login -->
    </div>
</template>

<script>
    import 'bootstrap'
    import { email, sameAs } from 'vuelidate/lib/validators'
    import { validatePassword } from '@/helpers/utilities'
    import {MODULES} from "@/store/modules-enum";
    import {AUTH_ACTIONS} from "@/store/auth/actions-enum";
    import {mapActions} from "vuex";
    import $ from "jquery";

    export default {
        name: "RecoveryPassword",
        data () {
            return {
                password: '',
                passwordConfirm: '',
            }
        },
        validations: {
            email: {
                email
            },
            password: {
                validatePassword
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        },
        mounted() {
            this.verifyToken()
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.SEND_RESTORE_PASSWORD]),
            verifyToken () {
                if (this.$route.query == null || this.$route.query.token == null || this.$route.query.token === "") {
                    this.goToLogin()
                }
            },
            goToLogin () {
                // this.$router.push({name: 'Auth', query: {type: 'login'}})
                window.location = `${process.env.VUE_APP_ORIGIN}${process.env.BASE_URL}auth?type=login`;
            },
            updatePassword () {
                const context = this
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
                    this.$emit('is-components-ready', 'recoveryPassword', false)
                    this.sendRestorePassword({
                        token: this.$route.query.token,
                        password: this.password
                    })
                        .then(() => {
                            context.hideOverlay();
                            context.$emit('is-components-ready', 'recoveryPassword', true)
                            $('#modalRestorePasswordSuccess').modal({backdrop: 'static', keyboard: false})
                        })
                        .catch(() => {
                            context.hideOverlay();
                            context.$emit('is-components-ready', 'recoveryPassword', true)
                            $('#modalError').modal({backdrop: 'static', keyboard: false})
                            $('#message-body').html(context.$t('recoveryPassword.modale.errore.generale'))
                        })
                }
            },
            hideOverlay () {
                $('#modalOverlayLogin').modal('hide')
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .pl-vertical-align-input {
            padding-left: 14px;
        }

        .custom-font {
            font-size: 0.85em;
            line-height: 1.1;
        }

        #modalOverlayLogin {
            background-color: #00000082;
        }
    }
</style>